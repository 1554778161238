/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */


/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
    outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
    margin: 0;
    padding: 0;
}

.flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
    width: 100%;
    display: block;
}

.flex-pauseplay span {
    text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
    display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider {
    margin: 0 0 60px;
    background: #fff;
    border: 4px solid #fff;
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    -o-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    zoom: 1;
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.flexslider .slides {
    zoom: 1;
}

.carousel li {
    margin-right: 5px;
}

/* Direction Nav */
.flex-direction-nav {
    *height: 0;
}

.flex-direction-nav a {
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
}

.flex-direction-nav .flex-prev {
    left: -50px;
}

.flex-direction-nav .flex-next {
    right: -50px;
    text-align: right;
}

.flexslider:hover .flex-prev {
    opacity: 0.7;
    left: 10px;
}

.flexslider:hover .flex-next {
    opacity: 0.7;
    right: 10px;
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
    opacity: 1;
}

.flex-direction-nav .flex-disabled {
    opacity: 0 !important;
    filter: alpha(opacity=0);
    cursor: default;
}

.flex-direction-nav a:before {
    font-size: 40px;
    display: inline-block;
    content: '\f001';
}

.flex-direction-nav a.flex-next:before {
    content: '\f002';
}

/* Pause/Play */
.flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
}

.flex-pauseplay a:before {
    font-size: 20px;
    display: inline-block;
    content: '\f004';
}

.flex-pauseplay a:hover {
    opacity: 1;
}

.flex-pauseplay a.flex-play:before {
    content: '\f003';
}

/* Control Nav */
.flex-control-nav {
    width: 100%;
    position: relative;
    text-align: center;
}

.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.flex-control-paging li a {
    width: 13px;
    height: 13px;
    display: block;
    background: #5c696a;
    background: #fff;
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    border: 3px solid #c9cbca;
}

.flex-control-paging li a:hover {
    background: #212024;
}

.flex-control-paging li a.flex-active {
    background: #fff;
    background: #212024;
    cursor: default;
}

.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
}

.flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
}

.flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: .7;
    cursor: pointer;
}

.flex-control-thumbs img:hover {
    opacity: 1;
}

.flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default;
}

@media screen and (max-width: 860px) {
    .flex-direction-nav .flex-prev {
        opacity: 1;
        left: 10px;
    }

    .flex-direction-nav .flex-next {
        opacity: 1;
        right: 10px;
    }
}