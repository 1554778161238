/* 320px (iPhone) */
@media screen and (max-width: 320px) {
}

/* 480px (WVGA - Low End Windows Phone) */
@media screen and (max-width: 480px) {
    .wrapper {
        background: #f3f5f8;
        height: 640px;
    }

    .iphone {
        display: none;
    }

    .fluid-white {
        display: none;
    }

    .wp2, .wp4, .wp5, .light-box {
        margin-bottom: 30px;
    }

    .legals {
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .credit {
        text-align: center;
    }
}

/* xGA iPad */
@media screen and (max-width: 1024px) {
    .wp3 {
        background-position: 50px 0;
    }

    .swag {
        background-attachment: scroll;
    }

    .subscribe {
        background-attachment: scroll;
    }
}

/* 1200px (Small Desktop, Netbook) */
@media screen and (max-width: 1280px) {
    .wp3 {
        background-position: 50px 0;
    }
}

/* Bootstrap 990px Breakpoint to 480px */
@media screen and (min-width: 480px) and (max-width: 991px) {
    .iphone {
        display: none;
    }

    .fluid-white {
        display: none;
    }

    .wrapper {
        background: #f3f5f8;
        height: 540px;
    }

    .wp2, .wp4, .wp5, .light-box {
        margin-bottom: 30px;
    }

    .legals {
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .credit {
        text-align: center;
    }
}

/* Menu changes for desktop view */
@media screen and (min-width: 700px) {
    .pull.active {
        height: 75px;
        overflow: visible;
        transition: all .5s ease-in-out;
    }

    nav ul li {
        border-bottom: 0;
        float: left;
        margin-top: 5px;
        text-align: center;
        width: 13.35vw;
        position: relative;
    }

    nav ul li ul {
        position: absolute;
        background: #242830;
        display: none;
    }

    nav ul li:hover ul {
        display: block;
    }

    nav ul li a {
        padding: 20px 0;
    }

    nav ul li .indicator {
        display: none;
    }

    .top-nav svg {
        display: block;
        margin: auto;
    }

    .col-xs-8.col-xs-offset-2 {
        margin: auto;
        width: 98%;
    }
}