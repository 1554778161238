
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fefefe;
    z-index: 99;
    height: 100%;
}

#status {
    width: 80px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -40px;
}

#spinningSquaresG {
    position: relative;
    width: 80px;
    height: 20px
}

.spinningSquaresG {
    position: absolute;
    top: 0;
    background-color: #1bbc9b;
    width: 20px;
    height: 20px;
    -moz-animation-name: bounce_spinningSquaresG;
    -moz-animation-duration: 0.8s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: linear;
    -moz-transform: scale(.3);
    -webkit-animation-name: bounce_spinningSquaresG;
    -webkit-animation-duration: 0.8s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: linear;
    -webkit-transform: scale(.3);
    -ms-animation-name: bounce_spinningSquaresG;
    -ms-animation-duration: 0.8s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-direction: linear;
    -ms-transform: scale(.3);
    -o-animation-name: bounce_spinningSquaresG;
    -o-animation-duration: 0.8s;
    -o-animation-iteration-count: infinite;
    -o-animation-direction: linear;
    -o-transform: scale(.3);
    animation-name: bounce_spinningSquaresG;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: linear;
    transform: scale(.3);
}

#spinningSquaresG_1 {
    left: 0;
    -moz-animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
    -ms-animation-delay: 0.3s;
    -o-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

#spinningSquaresG_2 {
    left: 20px;
    -moz-animation-delay: 0.36s;
    -webkit-animation-delay: 0.36s;
    -ms-animation-delay: 0.36s;
    -o-animation-delay: 0.36s;
    animation-delay: 0.36s;
}

#spinningSquaresG_3 {
    left: 40px;
    -moz-animation-delay: 0.42s;
    -webkit-animation-delay: 0.42s;
    -ms-animation-delay: 0.42s;
    -o-animation-delay: 0.42s;
    animation-delay: 0.42s;
}

#spinningSquaresG_4 {
    left: 60px;
    -moz-animation-delay: 0.48s;
    -webkit-animation-delay: 0.48s;
    -ms-animation-delay: 0.48s;
    -o-animation-delay: 0.48s;
    animation-delay: 0.48s;
}

@-moz-keyframes bounce_spinningSquaresG {
    0% {
        background-color: #1bbc9b;
    }

    100% {
        background-color: #2D3E50;
    }

}


@-webkit-keyframes bounce_spinningSquaresG {
    0% {
        background-color: #1bbc9b;
    }

    100% {
        background-color: #2D3E50;
    }

}

@-moz-keyframes bounce_spinningSquaresG {
    0% {
        -moz-transform: scale(1);
        background-color: #1bbc9b;
    }

    100% {
        -moz-transform: scale(.3) rotate(90deg);
        background-color: #2D3E50;
    }

}

@-webkit-keyframes bounce_spinningSquaresG {
    0% {
        -webkit-transform: scale(1);
        background-color: #1bbc9b;
    }

    100% {
        -webkit-transform: scale(.3) rotate(90deg);
        background-color: #2D3E50;
    }

}

@-ms-keyframes bounce_spinningSquaresG {
    0% {
        -ms-transform: scale(1);
        background-color: #1bbc9b;
    }

    100% {
        -ms-transform: scale(.3) rotate(90deg);
        background-color: #2D3E50;
    }

}

@-o-keyframes bounce_spinningSquaresG {
    0% {
        -o-transform: scale(1);
        background-color: #1bbc9b;
    }

    100% {
        -o-transform: scale(.3) rotate(90deg);
        background-color: #2D3E50;
    }

}

@keyframes bounce_spinningSquaresG {
    0% {
        transform: scale(1);
        background-color: #1bbc9b;
    }

    100% {
        transform: scale(.3) rotate(90deg);
        background-color: #2D3E50;
    }

}


